<template>
  <div>
    <v-card>
      <!-- addBroadcast Dialog -->
      <v-card-text>
        <v-row class="mb-0 pb-0">
          <v-col cols="12" md="4">
            <v-btn block color="primary" @click="dialogCreate = true">
              <v-icon dark left>
                {{ icons.mdiPlus }}
              </v-icon>
              {{ `${$t('add')}` }} Autoreply
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
                outlined
                class="me-2"
              ></v-text-field>
              <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning" class="my-auto">
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="warning" v-on="on">
                    {{ icons.mdiAlert }}
                  </v-icon>
                </template>
                <span>{{ bannerAlertText }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        item-key="id"
        :headers="columnsChatbot"
        :search="search"
        :loading="loading"
        :items="listChatbot"
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
        }"
      >
        <template #[`item.chatbot_message`]="{ item }">
          <div>
            <v-chip class="m-6">{{
              item.expand['message_template'] ? item.expand['message_template'].message : item['custom_message']
            }}</v-chip>
            <!-- <v-img v-for="attachment in item.attachments" :src="attachment.uploadURL" width="180px"> </v-img> -->
          </div>
        </template>

        <template #[`item.status`]="{ item }">
          <v-switch v-model="item.status" @change="changeStatus(item.id, item.status)"></v-switch>
        </template>

        <template #[`item.keyword`]="{ item }">
          <div v-for="el in item.keyword">
            <v-chip class="m-6">{{ el }}</v-chip>
          </div>
        </template>
        <template #[`item.channel`]="{ item }">
          <!-- <div v-for="el in item.channel">
          </div> -->
          <v-tooltip
            bottom
            color="warning"
            v-if="!item.channel || (Object.keys(item.expand).length && item.expand.channel.status === 0)"
          >
            <template #activator="{ on, attrs }">
              <v-icon v-bind="attrs" color="warning" v-on="on">
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            <span>{{
              item.channel
                ? 'Channel disconnected'
                : 'Channel was deleted, please assign a new channel in edit chat bot'
            }}</span>
          </v-tooltip>
          <!-- <v-chip class="m-6"> {{ item.expand.channel.expand.instance_label_name.edited_name }}</v-chip> -->
          <v-chip class="m-6" v-if="item.channel && Object.keys(item.expand).length">
            {{
              Object.keys(item.expand).length
                ? item.expand.channel.type === 'chatwoot'
                  ? item.expand.channel.label
                  : item.expand.channel.expand.instance_label_name.edited_name || item.expand.channel.label
                : ''
            }}</v-chip
          >
        </template>

        <template #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="openEditChatbot(item)">
                <v-icon color="#1F979E">{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>Edit Chatbot</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="deleteProcess(item)">
                <v-icon color="#1F979E">{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }} Chatbot</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Dialog Chatbot -->
      <v-dialog v-model="dialogCreate" :width="changeMessage ? '1000px' : '600px'" persistent>
        <v-card>
          <v-card-actions>
            <h3 class="font-weight-bold" v-if="ChatbotEdit">
              {{ $t('Edit Keywords') }}
            </h3>
            <h3 class="font-weight-bold" v-if="!ChatbotEdit">
              {{ $t('Add Keywords') }}
            </h3>
            <v-spacer></v-spacer>
          </v-card-actions>
          <!-- <v-divider /> -->

          <div class="px-3 mx-3 mt-3">
            <v-card-text>
              <v-form>
                <v-row>
                  <v-col cols="12" class="my-0 py-0">
                    <!-- <v-text-field v-model="model.phoneNumber" outlined dense :label="`${$t('Phone')} / WA`" /> -->
                    <template>
                      <!-- :rules="[validators.required]" -->
                      <v-combobox
                        v-model="chatbotData.keyword"
                        chips
                        clearable
                        label="Keyword"
                        multiple
                        outlined
                        dense
                        deletable-chips
                      >
                        <template v-slot:selection="{ phones, item, select, selectedPhones }">
                          <v-chip
                            v-bind="phones"
                            clearable
                            :input-value="selectedPhones"
                            close
                            :rules="[validators.required]"
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </template>

                    <v-select
                      v-model="chatbotData.channel"
                      :items="allChannel"
                      item-text="label"
                      item-value="id"
                      outlined
                      label="Channel *"
                      hide-details
                      required
                      class="mb-6"
                      dense
                      attach
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </div>

          <v-text-field
            v-model="chatbotData.priority"
            label="Priority"
            prepend-icon="mdi-clock-time-four-outline"
            outlined
            dense
            class="m-4 mr-8"
            type="number"
          ></v-text-field>

          <v-row rows="12" align="center" class="mr-8">
            <v-col class="p-0">
              <v-menu
                ref="menu"
                v-model="timeDialog.timeStartDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chatbotData.timeStart"
                    label="Time Start"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="checkValueTime('start')"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="timeDialog.timeStartDialog" format="24hr" v-model="tempTime" full-width>
                  <template v-slot:default="{ save, cancel }">
                    <v-btn color="primary" class="mt-2 ml-n2" dense @click="saveTimeStart">Save</v-btn>
                    <v-btn class="error--text mt-2" dense @click="timeDialog.timeStartDialog = false">Cancel</v-btn>
                  </template>
                </v-time-picker>
              </v-menu>
            </v-col>
            <v-col class="p-0">
              <v-menu
                ref="menu"
                v-model="timeDialog.timeEndDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="chatbotData.timeEnd"
                    label="Time End"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="checkValueTime('end')"
                  ></v-text-field>
                </template>
                <v-time-picker v-if="timeDialog.timeEndDialog" v-model="tempTime" format="24hr" full-width>
                  <template v-slot:default="{ save, cancel }">
                    <v-btn color="primary" class="mt-2 ml-n2" @click="saveTimeEnd">Save</v-btn>
                    <v-btn class="error--text mt-2" @click="timeDialog.timeEndDialog = false">Cancel</v-btn>
                  </template>
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
            v-model="chatbotData.delay"
            label="Delay * (second)"
            prepend-icon="mdi-clock-time-four-outline"
            outlined
            dense
            type="number"
            class="m-4 mr-8"
          ></v-text-field>

          <v-row class="d-flex justify-center p-0">
            <v-col md="11" cols="11" class="pt-0 mt-n4">
              <v-switch
                v-model="changeMessage"
                class="col-md-3 col-sm-5 col-lg-6 col-12 py-4"
                :label="$t('broadcasts.customMessage')"
                hide-details
              ></v-switch>
              <v-autocomplete
                v-if="!changeMessage"
                label="Message Template *"
                :items="getMessageTemplate"
                v-model="selectedTemplate"
                outlined
                item-value="id"
                item-text="template_name"
                dense
              ></v-autocomplete>
            </v-col>
            <v-col md="11" cols="11" class="pt-0 mt-n4">
              <message-template
                v-if="changeMessage"
                ref="message-template"
                :saveWithCustomTemplate="saveWithCustomTemplate"
                :itemEditCustomTemplate="itemEditCustomTemplate"
                @update-template="e => updateCustomTemplate(e)"
              />
            </v-col>
          </v-row>

          <div class="mr-2 d-flex justify-end flex-row">
            <v-card-actions>
              <v-btn plain @click="cancelDialog(true)">
                <span class="error--text">{{ $t('Cancel') }}</span>
              </v-btn>
              <v-btn v-if="ChatbotEdit" color="primary" @click="updateChatBot">
                {{ $t('EOrdering.save') }}
              </v-btn>
              <v-btn v-if="!ChatbotEdit" color="primary" @click="checkCustomTemplate()">
                {{ $t('EOrdering.save') }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>

      <v-dialog v-model="openDeleteDialog" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Chatbot</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <h3>Do you want to Delete this Chatbot</h3>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="openDeleteDialog = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="success" @click="deleteChatBot(deletePayload)">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogLink.show" persistent width="600px">
        <v-card>
          <v-snackbar
            v-model="snackbarErrorLink"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ errMessageLink }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbarErrorLink = false">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ `${$t('add')}` }} Link</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <v-text-field
                v-model="dialogLink.label"
                outlined
                hide-details
                required
                label="Label"
                class="mb-2"
                dense
              ></v-text-field>
              <v-text-field
                v-model="dialogLink.url"
                outlined
                hide-details
                required
                label="Url"
                class="mb-2"
                dense
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#ffeeee" @click="cancelLink()">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="success" @click="saveLink(dialogLink)">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <pre>{{listTemplate}}</pre> -->
    </v-card>

    <v-snackbar v-model="errorAddValidationSnackbar" color="error" :top="true" style="z-index: 100000 !important">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorAddValidationSnackbar = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="errorDeleteValidationSnackbar" color="error" :top="true" style="z-index: 100000 !important">
      {{ errorMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="errorDeleteValidationSnackbar = false">
          {{ $t('Close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { default as clientPocketBase } from '@/helpers/pocketbase'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import { required } from '@core/utils/validation'
import {
  mdiAlert,
  mdiAttachment,
  mdiBroadcast,
  mdiChartMultiple,
  mdiChevronDown,
  mdiClose,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEye,
  mdiFormSelect,
  mdiInformation,
  mdiLink,
  mdiPaperclip,
  mdiPencil,
  mdiPencilOutline,
  mdiPlus,
  mdiPlusCircleOutline,
  mdiTrashCanOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { uuid } from 'vue-uuid'
import messageTemplate from './MessageTemplate.vue'
export default {
  name: 'Chatbot',
  components: { messageTemplate },
  mixins: [pouchDbMixin],
  setup() {
    const tab = ref('')
    const tabs = [{ title: 'Auto Reply' }, { title: 'Auto Follow Up' }]
    return {
      icons: {
        mdiPaperclip,
        mdiFormSelect,
        mdiChevronDown,
        mdiDotsVertical,
        mdiPlus,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiBroadcast,
        mdiEye,
        mdiContentCopy,
        mdiChartMultiple,
        mdiAlert,
        mdiLink,
        mdiAttachment,
        mdiPlusCircleOutline,
        mdiInformation,
        mdiClose,
        mdiPencilOutline,
        mdiTrashCanOutline,
      },
      validators: {
        required,
      },
    }
  },
  data() {
    return {
      errorDeleteValidationSnackbar: false,
      errorMessage: '',
      itemEditCustomTemplate: {},
      formAddTemplate: {},
      saveWithCustomTemplate: false,
      listChatbotBackup: [],
      deletePayload: null,
      openDeleteDialog: false,
      loading: false,
      listChatbot: [],
      shopData: [],
      search: '',
      chatbot: [],
      ChatbotEdit: false,
      lastChannelFlag: '',
      dialogCreate: false,
      listTemplate: [],
      dataIndex: Math.round(Math.random() * 100000),
      dialogLink: {
        id: '',
        show: false,
        label: '',
        url: '',
      },
      form: {
        name: '',
        phoneNumber: '',
        selectShop: null,
        botLeanguage: '',
        greetingMsg: '',
      },
      snackbarError: false,
      errMessage: '',
      snackbarErrorLink: false,
      errMessageLink: '',
      selectedInstance: {},
      chatbotId: '',
      messageReply: [{ id: '', message: '' }],
      chatbotData: {
        keyword: [],
        channel: '',
        priority: 1,
        timeStart: '',
        timeEnd: '',
        delay: null,
        switch: false,
      },
      changeMessage: false,
      timeDialog: {
        timeStartDialog: false,
        timeEndDialog: false,
      },
      tempTime: '',
      allChannel: [],
      getMessageTemplate: [],
      selectedTemplate: '',
      errorAddValidationSnackbar: false,
    }
  },
  computed: {
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    columnsChatbot() {
      return [
        { text: 'Keyword', value: 'keyword' },
        { text: 'Channel', value: `channel` },
        { text: 'List Of Reply', value: 'chatbot_message' },
        { text: 'Status', value: 'status' },
        // { text: this.$t('EOrdering.shopAddress'), value: 'showAddress' },
        { text: this.$t('eform.action'), value: 'action' },
        // { text: 'FAQ', value: 'enableFaq' },
        // { text: 'Promo', value: 'enablePromo' }
      ]
    },
  },
  async mounted() {
    clientPocketBase.collection('crm_chatbot').subscribe('*', e => this.fetchChatbotAutoReply())

    this.loading = true
    await this.fetchMessageTemplate()
    await this.fetchChannel()
    await this.fetchChatbotAutoReply()
    this.loading = false
  },
  methods: {
    checkValueTime(timeField) {
      const { timeStart, timeEnd } = this.chatbotData
      if (timeField === 'start') {
        if (timeStart) {
          this.tempTime = timeStart
        }
      } else {
        if (timeStart) {
          this.tempTime = timeEnd
        }
      }
    },
    updateCustomTemplate(template) {
      this.formAddTemplate = template
      this.createChatBot()
      this.saveWithCustomTemplate = false
    },
    checkCustomTemplate() {
      if (this.changeMessage) {
        this.saveWithCustomTemplate = true
      } else {
        this.createChatBot()
      }
    },
    saveTimeStart() {
      const parent = this
      parent.chatbotData.timeStart = parent.tempTime
      parent.timeDialog.timeStartDialog = false
    },
    async changeStatus(id, status) {
      try {
        // console.log('sayang', status)
        await clientPocketBase.collection('crm_chatbot').update(
          id,
          {
            status,
          },
          { $autoCancel: false },
        )
      } catch (error) {
        console.log(error)
      }
    },
    saveTimeEnd() {
      const parent = this
      parent.chatbotData.timeEnd = parent.tempTime
      parent.timeDialog.timeEndDialog = false
    },
    async fetchChannel(label) {
      const getChannel = await clientPocketBase.collection('crm_instances').getFullList(200, {
        expand: ' instance_label_name',
        filter: `broadcast_group = false`,
      })
      getChannel.forEach(inst => {
        if (Object.keys(inst.expand).length) {
          const editedName = inst.expand.instance_label_name.edited_name
          if (editedName) {
            inst.label = editedName
            inst.label_server = editedName
          }
        }
      })
      this.allChannel = getChannel.filter(instance => {
        if (this.user.limited_channel) {
          return (
            this.user.enabled_channel &&
            this.user.enabled_channel.find(channel => channel === instance._id || channel === instance.phone_number)
          )
        }
        return true
      })
    },
    async fetchChatbotAutoReply() {
      const getChatbot = await clientPocketBase.collection('crm_chatbot').getFullList(
        200,
        {
          expand: 'message_template, channel, channel.instance_label_name',
        },
        {
          $autoCancel: false,
        },
      )
      this.listChatbot = getChatbot
    },
    remove(item) {
      const filterChatbot = this.chatbotData.keyword.indexOf(item)
      if (filterChatbot !== -1) {
        this.chatbotData.keyword.splice(filterChatbot, 1)
      }
    },
    async fetchMessageTemplate(label) {
      const getMessageTemplate = await clientPocketBase.collection('crm_message_templates').getFullList(200)
      this.getMessageTemplate = getMessageTemplate
    },
    openLinkdialog(data) {
      this.dialogLink.id = data.id
      if (data.promoLink.length) {
        this.dialogLink.label = data.promoLink[0].label
        this.dialogLink.url = data.promoLink[0].urlButton.url
      }
      this.dialogLink.show = true
    },
    cancelLink() {
      this.dialogLink = {
        id: '',
        show: false,
        label: '',
        url: '',
      }
    },
    saveLink(data) {
      const validate = this.validateSaveLink(data)
      if (validate) {
        this.chatbotData.promoList[this.chatbotData.promoList.findIndex(el => el.id === data.id)].promoLink = [
          {
            label: data.label,
            urlButton: {
              displayText: data.label,
              url: data.url,
            },
            index: 1,
          },
        ]
        this.dialogLink.show = false
      }
    },
    cancelAttachment() {
      this.dialogUppy = {
        show: false,
        uppyShow: true,
        id: '',
        uppyAttachment: null,
        uppySettings: {
          restrictions: {
            maxFileSize: 100 * 1024 * 1024,
            allowedFileTypes: ['.jpg', '.jpeg', '.png'],
            // maxNumberOfFiles: 1,
          },
        },
        uppyDashboardSettings: {
          note: ' Image Only',
          // dipakai ketika edit
          // disabled: false,
        },
      }
    },
    deleteProcess(data) {
      this.deletePayload = data
      this.openDeleteDialog = true
    },
    async cancelDialog(checkbox = false) {
      this.dialogCreate = false
      this.ChatbotEdit = false
      this.lastChannelFlag = ''
      this.selectedInstance = {}
      this.messageReply = [
        {
          id: '',
          message: '',
        },
      ]
      this.chatbotData = {
        keyword: [],
        channel: '',
        status: false,
        selectedShop: {},
        selectedPhoneNumber: '',
        priority: 1,
        greetingMsg: '',
        useKeyword: false,
        keywordMsg: '',
        talkToCustMsg: '',
        serveTo: '',
        closingMsg: '',
        showAddress: false,
        enableFaq: false,
        faqList: [
          {
            questionMsg: '',
            asnwerMsg: '',
            useTemplate: false,
            selectedTemplate: '',
          },
        ],
        enablePromo: false,
        promoList: [
          {
            id: this.$nanoid(),
            selectedOption: '',
            selectedTemplate: '',
            fromShopBanner: false,
            promoTitle: '',
            promoMessage: '',
            promoAttachments: [],
            promoLink: [],
          },
        ],
      }
      const refs = this.$refs['message-template']
      if (refs) {
        refs.formAddTemplate = {
          template_name: 'Custom Template',
          category: {},
          language: '',
          message: '',
          quick_reply: false,
          link_attachment: '',
          last_update: '',
          attachments: [],
          id: '',
          on_reply: false,
          on_reply_message: {
            message: '',
            attachments: [],
          },
          listButton: [],
          buttonHeader: '',
          buttonFooter: '',
        }
        this.changeMessage = false
      }
      // if (checkbox) {
      //   this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
      // }
    },
    openEditChatbot(data) {
      // Destructure data object to individual variables for easier access
      const {
        id,
        keyword,
        channel,
        start_time,
        end_time,
        message_template,
        custom_message,
        attachments,
        list_buttons,
        priority,
        delay,
      } = data

      // Assign the data to corresponding variables
      this.itemEditCustomTemplate = {}
      this.dialogCreate = true
      this.ChatbotEdit = true
      this.chatbotData = {
        id,
        keyword,
        channel,
        priority,
        delay,
        timeStart: start_time,
        timeEnd: end_time,
      }
      this.changeMessage = !message_template
      this.selectedTemplate = message_template || null

      this.$nextTick(() => {
        const refs = this.$refs['message-template']
        this.formAddTemplate = {
          message: custom_message,
          attachments,
          listButton: list_buttons,
        }

        refs.formAddTemplate = this.formAddTemplate
        refs.photoLinks = this.formAddTemplate.attachments
      })
    },
    async updateChatBot() {
      try {
        const { id, keyword, timeStart, timeEnd, channel, delay, priority } = this.chatbotData

        const { message, listButton, attachments } = this.formAddTemplate

        // Set loading to true while the update process is running
        console.log(!keyword.length && (!timeStart || !timeEnd), '1', delay === null || !priority || priority <= 0, '2')
        if ((!keyword.length && (!timeStart || !timeEnd)) || delay === null || !priority || priority <= 0) {
          this.errorAddValidationSnackbar = true
          this.errorMessage = `Failed to create reply, Field cannot be empty`
        } else {
          this.loading = true

          if (this.changeMessage) {
            const refs = this.$refs['message-template']
            this.formAddTemplate.message = refs.formAddTemplate.message
          }

          const updatedChatbot = await clientPocketBase.collection('crm_chatbot').update(id, {
            keyword,
            custom_message: message,
            list_buttons: listButton,
            attachments,
            sub_id: this.user.sub_id,
            channel,
            delay,
            start_time: timeStart ? timeStart : '00:00',
            end_time: timeEnd ? timeEnd : '23:59',
            priority,
            status: true,
            message_template: this.changeMessage ? '' : this.selectedTemplate,
          })

          // Set loading to false after the update process is complete
          this.loading = false

          // Close the dialog and refresh the chatbot data
          this.cancelDialog()
          this.selectedTemplate = ''
        }
      } catch (error) {
        // Catch any errors and log them for debugging purposes
        console.error(error, 'error update chatbot')

        // Set loading to false in case of an error
        this.loading = false
      }
    },
    async deleteChatBot(data) {
      this.loading = true
      if (data.status) {
        this.errorDeleteValidationSnackbar = true
        this.errorMessage = `Failed to delete reply, keyword ${data.keyword.join(', ')} status is active`
      } else {
        const deleteChatbot = await clientPocketBase.collection('crm_chatbot').delete(data.id)
      }
      this.loading = false
      this.openDeleteDialog = false
      // this.fetchChatbotAutoReply()
    },
    deleteChatbotMessage(index) {
      this.messageReply.splice(index, 1)
    },
    addChatbotMessage() {
      this.messageReply.push({
        id: uuid.v1(),
        message: '',
      })
      //console.log(this.listLabel, '<<vira new column')
      this.labelOwner = ''
    },
    async createChatBot() {
      // Destructure the user object for easier access
      const { sub_id } = this.user

      // Destructure the chatbotData object for easier access
      const { keyword, channel, delay, timeStart, timeEnd, priority } = this.chatbotData

      // Destructure the formAddTemplate object for easier access
      const { message, listButton, attachments } = this.formAddTemplate
      console.log(priority, !priority, 'check ini nyund')
      if (
        (!keyword.length && !timeStart && !timeEnd) ||
        !channel ||
        (!message && !this.selectedTemplate) ||
        delay === null ||
        !priority ||
        priority <= 0
      ) {
        this.errorAddValidationSnackbar = true
        this.errorMessage = `Failed to create reply, Field cannot be empty`
      } else {
        // Indicate that the request is being processed
        this.loading = true
        // Create the chatbot and store the response in the newChatbot variable
        const newChatbot = await clientPocketBase.collection('crm_chatbot').create({
          keyword,
          custom_message: message,
          list_buttons: listButton,
          attachments,
          sub_id,
          channel,
          delay,
          start_time: timeStart ? timeStart : '00:00',
          end_time: timeEnd ? timeEnd : '23:59',
          priority,
          status: true,
          message_template: this.selectedTemplate,
        })

        // Fetch the updated list of chatbots
        // this.fetchChatbotAutoReply()

        // Update the loading state
        this.loading = false
        this.selectedTemplate = ''
        this.saveWithCustomTemplate = false
        this.changeMessage = false

        // Close the dialog
        this.cancelDialog()
      }
    },
    validateSaveLink(data) {
      let checkker = true
      if (!data.label || !data.url) {
        this.errMessageLInk = 'Label and Url is required'
        checkker = false
        this.snackbarErrorLink = true
        //console.log(this.errMessage)
      } else if (!data.url.includes('http')) {
        this.errMessageLink = this.$t('broadcasts.linkAlert')
        checkker = false
        this.snackbarErrorLink = true
        //console.log(this.errMessage)
      }
      return checkker
    },
    validateChatbotPayload() {
      let checkker = true
      if (!this.chatbotData.selectedPhoneNumber) {
        // serveTo
        this.errMessage = 'Phone Number is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.selectedShop.hasOwnProperty('id')) {
        this.errMessage = 'Shop is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.useKeyword && !this.chatbotData.keywordMsg) {
        this.errMessage = 'Keyword Message is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.greetingMsg) {
        this.errMessage = 'Greeting Message is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.serveTo) {
        this.errMessage = 'serveTo is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.talkToCustMsg) {
        this.errMessage = 'Talk to Cs Message Is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.closingMsg) {
        this.errMessage = 'Closing Message Is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.enableFaq && !this.chatbotData.faqList.length) {
        this.errMessage = 'Please set Faq message'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.enablePromo && !this.chatbotData.promoList.length) {
        this.errMessage = 'Please set Promo message'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      }
      if (this.chatbotData.enableFaq && this.chatbotData.faqList.length) {
        for (let i = 0; i < this.chatbotData.faqList.length; i++) {
          if (!this.chatbotData.faqList[i].questionMsg) {
            this.errMessage = 'Question message is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            !this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].answerMsg
          ) {
            this.errMessage = 'Answer message is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].selectedTemplate
          ) {
            this.errMessage = 'Answer message is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          }
        }
        //console.log(this.chatbotData.enablePromo, this.chatbotData.promoList.length)
      }
      if (this.chatbotData.enablePromo && this.chatbotData.promoList.length > 0) {
        for (let i = 0; i < this.chatbotData.promoList.length; i++) {
          //console.log(this.chatbotData.promoList)
          if (!this.chatbotData.promoList[i].promoTitle) {
            this.errMessage = 'Promo Title is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.promoList[i].promoTitle &&
            this.chatbotData.promoList[i].selectedOption == 0 &&
            !this.chatbotData.promoList[i].selectedTemplate
          ) {
            this.errMessage = 'Promo Output is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          }
          // else if (
          //   this.chatbotData.promoList[i].promoTitle &&
          //   this.chatbotData.promoList[i].useTemplate &&
          //   !this.chatbotData.promoList[i].selectedTemplate.hasOwnProperty('message')
          // ) {
          //   this.errMessage = 'Answer message is required'
          //   //console.log(this.errMessage)
          //   break
          // }
        }
      }
      return checkker
    },
    async removePromo(index) {
      this.chatbotData.promoList = this.chatbotData.promoList.filter((promo, i) => i !== index)
    },
    async addPromoList() {
      this.chatbotData.promoList.push({
        id: this.$nanoid(),
        selectedOption: '',
        selectedTemplate: '',
        fromShopBanner: false,
        promoTitle: '',
        promoMessage: '',
        promoAttachments: [],
        promoLink: [],
      })
    },
    async removeFaq(index) {
      this.chatbotData.faqList = this.chatbotData.faqList.filter((faq, i) => i !== index)
    },
    async addFaqList() {
      this.chatbotData.faqList.push({
        questionMsg: '',
        answerMsg: '',
        useTemplate: false,
        selectedTemplate: '',
      })
    },
    async getDataTemplate(status, remote = false) {
      const dataTemplate = await this.loadSettingsById('message_templates', remote)
      // eslint-disable-next-line no-param-reassign
      if (dataTemplate === null) status = 'null'
      switch (status) {
        case 'message_templates': {
          this.listTemplate = [...dataTemplate.value]
          break
        }
        default: {
          this.listTemplate = []
        }
      }
    },
  },
}
</script>
<style scoped>
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 450px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.text-area-span {
  margin-top: -90px;
}
.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}
@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}
.additional-divider {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 32px;
  margin-right: 32px;
}
.additional-faq {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 10px;
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
  .additional-faq {
    border-left: 0px;
    border-top: 2px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: #afd0ff;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }
}
</style>
