<template>
  <div>
    <v-card>
      <!-- addBroadcast Dialog -->
      <v-card-text>
        <v-row class="mb-0 pb-0">
          <v-col cols="12" md="4">
            <v-btn block color="primary" @click="dialogCreate = true">
              <v-icon dark left>
                {{ icons.mdiPlus }}
              </v-icon>
              {{ `${$t('insert')}` }} Keyword
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4">
            <div class="d-flex">
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
                outlined
                class="me-2"
              ></v-text-field>
              <v-tooltip v-if="statusChannelsBanner && bannerAlertText" bottom color="warning" class="my-auto">
                <template #activator="{ on, attrs }">
                  <v-icon v-bind="attrs" color="warning" v-on="on">
                    {{ icons.mdiAlert }}
                  </v-icon>
                </template>
                <span>{{ bannerAlertText }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        item-key="id"
        :headers="columnsChatbot"
        :search="search"
        :loading="loading"
        :items="listChatbot"
        class="text-no-wrap"
        :footer-props="{
          'items-per-page-text': $t('rowsPerPageLabel'),
        }"
      >
        <template #[`item.action`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="openEditChatbot(item)">
                <v-icon>{{ icons.mdiPencil }}</v-icon>
              </v-btn>
            </template>
            <span>Edit Chatbot</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn v-bind="attrs" icon v-on="on" @click="deleteProcess(item)">
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('delete') }} Chatbot</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <!-- Dialog Chatbot -->
      <v-dialog v-model="dialogCreate">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="8">
                <div class="text-h5">{{ ChatbotEdit ? $t('edit') : $t('create') }} Chatbot</div>
              </v-col>
              <v-col class="text-end" cols="4">
                <v-menu v-if="ChatbotEdit" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="chatbotData.status === false ? 'error' : 'success'"
                      class="my-auto"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <span :style="$vuetify.theme.isDark === true ? 'color: white;' : 'color: black;'">{{
                        chatbotData.status === false ? 'Inactive' : 'Active'
                      }}</span>
                      <v-icon color="#000000">
                        {{ icons.mdiChevronDown }}
                      </v-icon>
                    </v-chip>
                  </template>
                  <v-list>
                    <v-list-item @click="chatbotData.status = true">
                      <v-list-item-title>
                        <v-chip color="success"> Active </v-chip>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="chatbotData.status = false">
                      <v-list-item-title>
                        <v-chip color="error"> Non active </v-chip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider />
          <v-card-text class="height-dialog" id="yourScrollElementId">
            <v-snackbar
              v-model="snackbarError"
              color="error"
              :timeout="2000"
              :top="true"
              style="z-index: 100 !important"
            >
              {{ errMessage }}
              <template #action="{ attrs }">
                <v-btn text v-bind="attrs" @click="snackbarError = false">
                  {{ $t('close') }}
                </v-btn>
              </template>
            </v-snackbar>
            <v-form class="" lazy-validation>
              <!-- Shop Field -->
              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.selectShop') }} *<span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span"> : </span>
                  <!-- <pre>{{chatbotData.selectedShop}}</pre> -->
                  <!-- note items musbe list Shop -->
                  <v-select
                    v-model="chatbotData.selectedShop"
                    :items="shopData"
                    item-text="name"
                    return-object
                    outlined
                    :label="$t('EOrdering.selectShop')"
                    hide-details
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>
              <!-- End Shop Field -->
              <!-- Phone Number Field -->
              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} *<span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <!-- <pre>{{instances}}</pre> -->
                  <span class="order-text me-3 mb-3 mobile-span"> : </span>

                  <v-select
                    v-model="selectedInstance"
                    :items="instances"
                    item-text="label"
                    return-object
                    outlined
                    :label="$t('channels.channel')"
                    hide-details
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>
              <!-- End Phone Number Field -->
              <!-- Bot Leanguage Field -->
              <!-- depricated -->
              <!-- <v-row class="mb-n8">
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  class="flex "
                >
                  <p class="order-text me-3 mt-2">
                    Bot Language <span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <span class="order-text me-3 mb-3 mobile-span"> : </span>

                  <v-select
                    v-model="form.botLeanguage"
                    :items="instances"
                    item-text="label"
                    item-value="value"
                    outlined
                    :label="`Bot Language`"
                    hide-details
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
              </v-row> -->
              <!-- End Bot Leanguage Field -->

              <!-- Bot Leanguage Field -->
              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.greeting.msg') }} *<span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                  <v-textarea
                    v-model="chatbotData.greetingMsg"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.greeting.detail')"
                    class="mb-2"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>

              <v-row class="mb-n8" id="useKeyword">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <v-checkbox v-model="chatbotData.useKeyword" label="Use Keyword" color="primary">
                    {{ $t('EOrdering.keyword.msg') }}
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile"> </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>

              <div v-if="chatbotData.useKeyword">
                <v-row class="mb-n4">
                  <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  <v-col cols="12" md="3" sm="4" class="flex">
                    <p class="order-text me-3 mt-2">
                      {{ $t('EOrdering.keyword.msg') }} *
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                            {{ icons.mdiInformation }}
                          </v-icon>
                        </template>
                        <v-card>
                          <img class="mx-auto" src="./../../assets/images/Keyword Message.png" />
                        </v-card>
                      </v-menu>
                      <span class="mobile-col-show ms-3"> : </span>
                    </p>
                  </v-col>
                  <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                    <span class="order-text me-3 mb-3 mobile-span"> : </span>
                    <v-text-field
                      v-model="chatbotData.keywordMsg"
                      outlined
                      hide-details
                      required
                      :label="$t('EOrdering.keyword.detail')"
                      class="mb-2"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="1" class="mobile-col"> </v-col>
                </v-row>
              </div>

              <v-row class="mb-n4">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.talktoCS.msg') }} *
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                          {{ icons.mdiInformation }}
                        </v-icon>
                      </template>
                      <v-card>
                        <img class="mx-auto" src="./../../assets/images/Talk To CS Message.png" />
                      </v-card>
                    </v-menu>
                    <span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                  <v-textarea
                    v-model="chatbotData.talkToCustMsg"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.talktoCS.detail')"
                    class="mb-2"
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>

              <v-row class="mb-n2">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-4 mb-3 mobile-span"> </span>
                  <v-select
                    v-model="chatbotData.serveTo"
                    :items="dataListUsers"
                    item-text="email"
                    item-value="email"
                    outlined
                    :label="$t('EOrdering.serveTo')"
                    hide-details
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>

              <v-row class="mb-n8">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.closingMsg.msg') }} * <span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                  <span class="order-text me-3 mb-3 mobile-span text-area-span"> : </span>
                  <v-textarea
                    v-model="chatbotData.closingMsg"
                    outlined
                    hide-details
                    required
                    :label="$t('EOrdering.closingMsg.detail')"
                    class="mb-2"
                    dense
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="1" class="mobile-col"> </v-col>
              </v-row>

              <v-row class="mt-8 mb-n8">
                <v-col cols="12">
                  <div class="additional-divider">
                    <span class="text-subtitle-1 font-weight-black primary--text">{{
                      $t('EOrdering.additionalMenu')
                    }}</span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mb-n12">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <v-checkbox v-model="chatbotData.showAddress" :label="$t('EOrdering.shopAddress')" color="primary">
                  </v-checkbox>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile"> </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>

              <v-row class="mb-n8" id="enableFaq">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <v-checkbox v-model="chatbotData.enableFaq" label="FAQ" color="primary"> </v-checkbox>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile"> </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>

              <div v-if="chatbotData.enableFaq">
                <div v-for="(faq, i) in chatbotData.faqList" :key="i + `index-faq`">
                  <v-row class="mb-n8">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <p class="order-text me-3 mt-2">FAQ {{ i + 1 }} <span class="mobile-col-show ms-3"> : </span></p>
                    </v-col>
                    <v-col cols="10" md="5" sm="6" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-3 mb-3 mobile-span"> : </span>
                      <v-text-field
                        v-model="faq.questionMsg"
                        outlined
                        hide-details
                        required
                        :label="`${$t('EOrdering.faqMsg.qs')} *`"
                        class="mb-2"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" md="2" sm="2" class="col-margin-top-mobile">
                      <v-icon
                        v-if="chatbotData.faqList.length > 1"
                        class="mt-1"
                        size="28"
                        color="error"
                        @click="removeFaq(i)"
                      >
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-col>
                  </v-row>

                  <v-row class="mb-n8">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <!-- <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} <span class="mobile-col-show ms-3"> : </span>
                  </p> -->
                    </v-col>
                    <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-4 mb-3 mobile-span"> </span>
                      <v-checkbox
                        v-model="faq.useTemplate"
                        color="primary"
                        :label="`${$t('messageTemplates.fromTemplates')}`"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  </v-row>

                  <v-row v-if="faq.useTemplate" class="mb-n8">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <!-- <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} <span class="mobile-col-show ms-3"> : </span>
                  </p> -->
                    </v-col>
                    <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-4 mb-4 mobile-span"> </span>
                      <v-select
                        v-model="faq.selectedTemplate"
                        :items="listTemplate"
                        item-text="template_name"
                        item-value="_id"
                        outlined
                        :label="`${$t('select')} Template *`"
                        hide-details
                        required
                        class="mb-10"
                        dense
                        attach
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  </v-row>

                  <v-row v-if="!faq.useTemplate" class="mb-n8">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <!-- <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} <span class="mobile-col-show ms-3"> : </span>
                  </p> -->
                    </v-col>
                    <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-4 mb-4 mobile-span"> </span>
                      <v-text-field
                        v-model="faq.answerMsg"
                        outlined
                        hide-details
                        required
                        :label="`${$t('EOrdering.faqMsg.as')} *`"
                        class="mb-10"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  </v-row>

                  <!-- <v-icon
                    color="error"
                    @click="removeFaq(i)"
                  >
                    {{ icons.mdiClose }}
                  </v-icon> -->
                </div>
                <v-row class="mt-8">
                  <v-col cols="12" class="text-end">
                    <div class="additional-faq">
                      <!-- <v-btn
                      class="mt-2"
                      small
                    > -->
                      <div>
                        <v-btn class="text-capitalize" x-small @click="addFaqList">
                          <v-icon color="primary" class="me-1">
                            {{ icons.mdiPlusCircleOutline }}
                          </v-icon>
                          <span class="text-subtitle-1 font-weight-regular primary--text">{{
                            $t('EOrdering.addNewFaq')
                          }}</span>
                        </v-btn>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                              {{ icons.mdiInformation }}
                            </v-icon>
                          </template>
                          <v-card>
                            <img class="mx-auto" src="./../../assets/images/Add FAQ Menu.png" />
                          </v-card>
                        </v-menu>
                      </div>

                      <!-- </v-btn> -->
                    </div>
                  </v-col>
                </v-row>
              </div>

              <!-- <v-row class="mb-n8">
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  class="flex "
                >
                  <p class="order-text me-3 mt-2">
                    FAQ 2 <span class="mobile-col-show ms-3"> : </span>
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <span class="order-text me-3 mb-3 mobile-span"> : </span>
                  <v-text-field
                    v-model="form.greetingMsg"
                    outlined
                    hide-details
                    required
                    label="Input Question Message"
                    class="mb-2"
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  class="mobile-col"
                >
                </v-col>
              </v-row>

              <v-row class="mb-n8">
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  class="flex "
                >
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <span class="order-text me-4 mb-3 mobile-span">  </span>
                  <v-checkbox
                    color="primary"
                    label="Use Template"
                  ></v-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
              </v-row>

              <v-row class="mb-n8">
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
                <v-col
                  cols="12"
                  md="3"
                  sm="4"
                  class="flex "
                >
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                  sm="8"
                  class="d-flex col-margin-top-mobile"
                >
                  <span class="order-text me-4 mb-4 mobile-span">  </span>
                  <v-select
                    v-model="form.phoneNumber"
                    :items="instances"
                    item-text="label"
                    item-value="value"
                    outlined
                    :label="`Select Template`"
                    hide-details
                    required
                    class="mb-3"
                    dense
                    attach
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                  class="flex  mobile-col"
                ></v-col>
              </v-row> -->

              <v-row class="mb-n8 mt-n8" id="enablePromo">
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                <v-col cols="12" md="3" sm="4" class="flex">
                  <v-checkbox v-model="chatbotData.enablePromo" label="Promo" color="primary"> </v-checkbox>
                </v-col>
                <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile"> </v-col>
                <v-col cols="12" md="1" class="flex mobile-col"></v-col>
              </v-row>

              <div v-if="chatbotData.enablePromo">
                <div v-for="(promo, indexPromo) in chatbotData.promoList" :key="indexPromo + 'index-promo'">
                  <v-row class="mb-n8">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <p class="order-text me-3 mt-2">
                        Promo {{ indexPromo + 1 }} <span class="mobile-col-show ms-3"> : </span>
                      </p>
                    </v-col>
                    <v-col cols="10" md="7" sm="6" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-3 mb-3 mobile-span"> : </span>
                      <v-select
                        v-model="promo.selectedOption"
                        :items="promoSource"
                        item-text="label"
                        item-value="value"
                        outlined
                        :label="`Select Source *`"
                        hide-details
                        required
                        class="mb-3"
                        dense
                        attach
                      ></v-select>
                    </v-col>
                    <v-col cols="2" md="1" sm="2" class="col-margin-top-mobile">
                      <v-icon
                        v-if="chatbotData.promoList.length > 1"
                        class="mt-1"
                        size="28"
                        color="error"
                        @click="removePromo(indexPromo)"
                      >
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-col>
                  </v-row>

                  <v-row class="">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex">
                      <!-- <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} <span class="mobile-col-show ms-3"> : </span>
                  </p> -->
                    </v-col>
                    <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-4 mb-4 mobile-span"> </span>
                      <v-text-field
                        v-model="promo.promoTitle"
                        outlined
                        hide-details
                        required
                        label="Input Promo Title *"
                        class="mb-2"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  </v-row>

                  <v-row v-if="promo.selectedOption === 0" class="mb-2 mt-n4">
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                    <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                      <span class="order-text me-4 mb-4 mobile-span"> </span>
                      <v-select
                        v-model="promo.selectedTemplate"
                        :items="listTemplate"
                        item-text="template_name"
                        item-value="_id"
                        outlined
                        :label="`Select Template *`"
                        hide-details
                        required
                        class="mb-3"
                        dense
                        attach
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                  </v-row>

                  <div v-if="promo.selectedOption === 2">
                    <v-row class="mb-n8 mt-n4">
                      <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                      <v-col cols="12" md="3" sm="4" class="flex"> </v-col>
                      <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                        <span class="order-text me-4 mb-4 mobile-span text-area-span"> </span>
                        <v-textarea
                          v-model="promo.promoMessage"
                          outlined
                          hide-details
                          required
                          label="Input Promo Message *"
                          class="mb-2"
                          dense
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    </v-row>

                    <v-row class="mb-4">
                      <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                      <v-col cols="12" md="3" sm="4" class="flex">
                        <!-- <p class="order-text me-3 mt-2">
                    {{ $t('EOrdering.phoneNumber') }} <span class="mobile-col-show ms-3"> : </span>
                  </p> -->
                      </v-col>
                      <v-col cols="12" md="7" sm="8" class="d-flex col-margin-top-mobile">
                        <v-btn class="text-capitalize" x-small @click="openUppydialog(promo)">
                          <v-icon class="me-1" color="primary">
                            {{ icons.mdiPaperclip }}
                          </v-icon>
                          <span class="text-subtitle-1 font-weight-regular primary--text"
                            >{{ `${$t('add')}` }} {{ $t('history.attachments') }}</span
                          >
                        </v-btn>

                        <v-btn class="text-capitalize" x-small @click="openLinkdialog(promo)">
                          <v-icon class="me-1" color="primary">
                            {{ icons.mdiLink }}
                          </v-icon>
                          <span class="text-subtitle-1 font-weight-regular primary--text"
                            >{{ `${$t('add')}` }} Link</span
                          >
                        </v-btn>

                        <!-- <span class="order-text me-4 mb-4 mobile-span text-area-span">  </span>
                  <v-textarea
                    v-model="form.greetingMsg"
                    outlined
                    hide-details
                    required
                    label="Input Promo Message"
                    class="mb-2"
                    dense
                  ></v-textarea> -->
                      </v-col>
                      <v-col cols="12" md="1" class="flex mobile-col"></v-col>
                    </v-row>
                    <!-- <pre>{{promo.id}}</pre> -->
                    <!-- <uppyUploader
                :ref="`uppy-data${promo.id}`"
                :key="`uppy-data${promo.id}`"
                class="margin-start4"
                :data-index="`uppy-data${promo.id}`"
                :status="'add'"
                :data-uppy="promo.promoAttachments"
                :config="uppySettings"
                :dashboard-config="uppyDashboardSettings"
              /> -->
                  </div>
                </div>

                <v-row class="mt-8 mb-n8">
                  <v-col cols="12" class="text-end">
                    <div class="additional-faq">
                      <!-- <v-btn
                      class="mt-2"
                      small
                    > -->
                      <div>
                        <v-btn class="text-capitalize" x-small @click="addPromoList">
                          <v-icon color="primary" class="me-1">
                            {{ icons.mdiPlusCircleOutline }}
                          </v-icon>
                          <span class="text-subtitle-1 font-weight-regular primary--text">{{ $t('add') }} Promo</span>
                        </v-btn>
                        <!-- </v-btn> -->
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" class="mt-n1 ms-1" v-on="on">
                              {{ icons.mdiInformation }}
                            </v-icon>
                          </template>
                          <v-card>
                            <!-- <img
                class="mx-auto mt-16"
                src="./../../assets/images/"
              /> -->
                          </v-card>
                        </v-menu>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <!-- End Bot Leanguage Field -->
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="#ffeeee" @click="cancelDialog(true)">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn v-if="ChatbotEdit" color="success" @click="updateChatBot">
              {{ $t('EOrdering.save') }}
            </v-btn>
            <v-btn v-if="!ChatbotEdit" color="success" @click="createChatBot">
              {{ $t('EOrdering.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openDeleteDialog" width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ $t('delete') }} Chatbot</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <h3>Do you want to Delete this Chatbot</h3>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="#ffeeee" @click="openDeleteDialog = false">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="deleteChatBot(deletePayload)">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogUppy.show" persistent width="600px">
        <v-card>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ `${$t('add')}` }} attachments</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <uppyUploader
                v-if="dialogUppy.uppyShow"
                :ref="`uppy-data${dialogUppy.id}`"
                :key="`uppy-data${dialogUppy.id}`"
                class="margin-start4"
                :data-index="`uppy-data${dialogUppy.id}`"
                :status="'add'"
                :data-uppy="dialogUppy.promoAttachments"
                :config="dialogUppy.uppySettings"
                :dashboard-config="dialogUppy.uppyDashboardSettings"
              />
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="#ffeeee" @click="cancelAttachment()">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveAttachment(dialogUppy)">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogLink.show" persistent width="600px">
        <v-card>
          <v-snackbar
            v-model="snackbarErrorLink"
            color="error"
            :timeout="2000"
            :top="true"
            style="z-index: 100 !important"
          >
            {{ errMessageLink }}
            <template #action="{ attrs }">
              <v-btn text v-bind="attrs" @click="snackbarErrorLink = false">
                {{ $t('close') }}
              </v-btn>
            </template>
          </v-snackbar>
          <v-card-title class="mobile-header">
            <div class="text-h5">{{ `${$t('add')}` }} Link</div>
          </v-card-title>
          <v-divider />
          <v-card elevation="0">
            <v-card-text>
              <v-text-field
                v-model="dialogLink.label"
                outlined
                hide-details
                required
                label="Label"
                class="mb-2"
                dense
              ></v-text-field>
              <v-text-field
                v-model="dialogLink.url"
                outlined
                hide-details
                required
                label="Url"
                class="mb-2"
                dense
              ></v-text-field>
            </v-card-text>
          </v-card>
          <v-divider />
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="#ffeeee" @click="cancelLink()">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>

            <v-btn color="success" @click="saveLink(dialogLink)">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <pre>{{listTemplate}}</pre> -->
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import uppyUploader from '@/components/UppyUploader.vue'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import {
  mdiAlert,
  mdiAttachment,
  mdiBroadcast,
  mdiChartMultiple,
  mdiChevronDown,
  mdiClose,
  mdiCloseCircle,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEye,
  mdiFormSelect,
  mdiInformation,
  mdiLink,
  mdiPaperclip,
  mdiPencil,
  mdiPlus,
  mdiPlusCircleOutline,
} from '@mdi/js'

export default {
  name: 'AutoFollowUp',
  components: { uppyUploader },
  mixins: [pouchDbMixin],
  setup() {
    return {
      icons: {
        mdiPaperclip,
        mdiFormSelect,
        mdiChevronDown,
        mdiDotsVertical,
        mdiPlus,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiBroadcast,
        mdiEye,
        mdiContentCopy,
        mdiChartMultiple,
        mdiAlert,
        mdiLink,
        mdiAttachment,
        mdiPlusCircleOutline,
        mdiInformation,
        mdiClose,
      },
    }
  },
  data() {
    return {
      listChatbotBackup: [],
      deletePayload: null,
      openDeleteDialog: false,
      loading: false,
      listChatbot: [],
      shopData: [],
      search: '',
      chatbot: [],
      ChatbotEdit: false,
      lastChannelFlag: '',
      dialogCreate: false,

      listTemplate: [],
      dataIndex: Math.round(Math.random() * 100000),
      propsUppyUploader: null,
      uppySettings: {
        restrictions: {
          maxFileSize: 100 * 1024 * 1024,
          allowedFileTypes: ['.jpg', '.jpeg', '.png'],

          // maxNumberOfFiles: 1,
        },
      },
      uppyDashboardSettings: {
        note: ' Image Only',

        // dipakai ketika edit
        // disabled: false,
      },
      dialogLink: {
        id: '',
        show: false,
        label: '',

        url: '',
      },
      dialogUppy: {
        show: false,
        uppyShow: true,
        id: '',
        uppyAttachment: null,

        uppySettings: {
          restrictions: {
            maxFileSize: 100 * 1024 * 1024,
            allowedFileTypes: ['.jpg', '.jpeg', '.png'],

            // maxNumberOfFiles: 1,
          },
        },
        uppyDashboardSettings: {
          note: ' Image Only',

          // dipakai ketika edit
          // disabled: false,
        },
      },
      form: {
        name: '',
        phoneNumber: '',
        selectShop: null,
        botLeanguage: '',
        greetingMsg: '',
      },
      snackbarError: false,
      errMessage: '',
      snackbarErrorLink: false,
      errMessageLink: '',
      selectedInstance: {},
      // chatbotData: {
      //   status: true,
      //   selectedShop: {},
      //   selectedPhoneNumber: '',
      //   greetingMsg: '',
      //   useKeyword: false,
      //   keywordMsg: '',
      //   talkToCustMsg: '',
      //   serveTo: '',
      //   closingMsg: '',
      //   showAddress: false,
      //   enableFaq: false,
      //   faqList: [
      //     {
      //       questionMsg: '',
      //       asnwerMsg: '',
      //       useTemplate: false,
      //       selectedTemplate: '',
      //     },
      //   ],
      //   enablePromo: false,
      //   promoList: [],
      // },
      chatbotData: {
        keyword: [],
        channel: '',
        messageReply: [],
        time: '',
        priority: '',
      },
      promoSource: [
        {
          label: 'From Template',
          value: 0,
        },
        {
          label: 'From eShop Active Banner',
          value: 1,
        },
        {
          label: 'Custom Message',
          value: 2,
        },
      ],
      instances: [],
    }
  },
  async mounted() {
    const listInstance = []
    const instances = await this.$store.dispatch('auth/getAllInstances')
    if (instances.length !== 0) {
      instances.value.forEach(instance => {
        if (this.listChatbot.findIndex(el => instance.phone_number == el.selectedPhoneNumber) != -1) {
          listInstance.push({
            label: `${
              instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
                ? instance.label_server
                : instance.label
            } - (${instance.phone_number})`,
            value: instance.phone_number,
            instance: instance._id,
          })
        } else {
          listInstance.push({
            label: `${
              instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
                ? instance.label_server
                : instance.label
            } - (${instance.phone_number})`,
            value: instance.phone_number,
            instance: instance._id,
            disabled: false,
          })
        }
      })
      this.instances = listInstance
    }
  },
  computed: {
    // instances() {
    //   const listInstance = []

    //   // //console.log(this.$store.getters['auth/getListInstance'])
    //   // //console.log(this.listChatbot, '============')

    //   if (this.$store.getters['auth/getListInstance'].length !== 0) {
    //     this.$store.getters['auth/getListInstance'].value.forEach(instance => {
    //       // //console.log(this.listChatbot.findIndex(el => instance.phone_number != el.selectedPhoneNumber))
    //       if (this.listChatbot.findIndex(el => instance.phone_number == el.selectedPhoneNumber) != -1) {
    //         listInstance.push({
    //           label: `${
    //             instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
    //               ? instance.label_server
    //               : instance.label
    //           } - (${instance.phone_number})`,
    //           value: instance.phone_number,
    //           instance: instance._id,
    //           disabled: true,
    //         })
    //       } else {
    //         listInstance.push({
    //           label: `${
    //             instance.label.startsWith('-') || instance.label.includes('Default') || instance.label.includes('62')
    //               ? instance.label_server
    //               : instance.label
    //           } - (${instance.phone_number})`,
    //           value: instance.phone_number,
    //           instance: instance._id,
    //           disabled: false,
    //         })
    //       }
    //     })
    //   }

    //   return listInstance
    // },
    bannerAlertText() {
      return this.$store.getters['inbox/getBannerAlertText']
    },
    statusChannelsBanner() {
      return this.$store.getters['global/getStatusChannels']
    },
    dataListUsers() {
      return this.$store.getters['auth/getListUsers']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    columnsChatbot() {
      return [
        { text: 'Rule', value: 'keyword' },
        { text: 'Channel', value: 'channel' },
        { text: 'List Of Reply', value: 'messageReply' },
        { text: 'Time', value: 'time' },
        { text: 'Prioritas', value: 'priority' },
        { text: this.$t('eform.action'), value: 'action' },
        // { text: 'FAQ', value: 'enableFaq' },
        // { text: 'Promo', value: 'enablePromo' },
      ]
    },
  },
  async mounted() {
    this.loading = true
    // this.chatbotData.promoList.push({
    //   id: this.$nanoid(),
    //   selectedOption: '',
    //   selectedTemplate: '',
    //   fromShopBanner: false,
    //   promoTitle: '',
    //   promoMessage: '',
    //   promoAttachments: [],
    //   promoLink: [],

    //   // uppySettings: {
    //   //   restrictions: {
    //   //     maxFileSize: 100 * 1024 * 1024,
    //   //     allowedFileTypes: ['.jpg', '.jpeg', '.png'],
    //   //     maxNumberOfFiles: 1,
    //   //   },
    //   // },
    //   // uppyDashboardSettings: {
    //   //   note: ' Image Only',

    //   //   // dipakai ketika edit
    //   //   // disabled: false,
    //   // },
    // })

    //  {
    //       "label": "go ",
    //       "urlButton": {
    //         "displayText": "go ",
    //         "url": "https://www.youtube.com/"
    //       },
    //       "index": 1
    //     },
    // const conn = await this.checkStatusConnectionServer()
    this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
    const shopPayload = await this.loadEordering({ sub_id: this.user.sub_id })
    if (shopPayload.length) {
      shopPayload.forEach(el => {
        this.shopData.push({ ...el.data, id: el._id })
      })
    }
    await this.getMessageTemplate()

    // await this.connectSettings(true, false)

    // this.getDataTemplate('message_templates', conn).then(() => {
    //   if (conn) this.replicateFromPouchDbGlobal(process.env.VUE_APP_DB_SETTING_NAME, this.user)
    // })
    this.loading = false

    // //console.log(this.listChatbot, '=============')
  },
  methods: {
    async getMessageTemplate() {
      // this.looading.table = true
      const messageTemplatePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'message_template',
      })

      if (messageTemplatePg.status) {
        this.listTemplate = messageTemplatePg.data === null ? [] : messageTemplatePg.data.value
      }

      // this.looading.table = false
    },
    openLinkdialog(data) {
      this.dialogLink.id = data.id
      if (data.promoLink.length) {
        this.dialogLink.label = data.promoLink[0].label
        this.dialogLink.url = data.promoLink[0].urlButton.url
      }
      this.dialogLink.show = true
    },
    cancelLink() {
      this.dialogLink = {
        id: '',
        show: false,
        label: '',

        url: '',
      }
    },
    saveLink(data) {
      const validate = this.validateSaveLink(data)
      if (validate) {
        this.chatbotData.promoList[this.chatbotData.promoList.findIndex(el => el.id === data.id)].promoLink = [
          {
            label: data.label,
            urlButton: {
              displayText: data.label,
              url: data.url,
            },
            index: 1,
          },
        ]
        this.dialogLink.show = false
      }
    },
    openUppydialog(data) {
      // //console.log(data)
      if (data.promoAttachments.length) {
        this.dialogUppy.promoAttachments = {
          attachments: data.promoAttachments,
        }
        this.dialogUppy.uppyDashboardSettings.disabled = false
      } else {
        this.dialogUppy.promoAttachments = null
      }

      this.dialogUppy.id = data.id
      this.dialogUppy.show = true
    },
    cancelAttachment() {
      this.dialogUppy = {
        show: false,
        uppyShow: true,
        id: '',
        uppyAttachment: null,

        uppySettings: {
          restrictions: {
            maxFileSize: 100 * 1024 * 1024,
            allowedFileTypes: ['.jpg', '.jpeg', '.png'],

            // maxNumberOfFiles: 1,
          },
        },
        uppyDashboardSettings: {
          note: ' Image Only',

          // dipakai ketika edit
          // disabled: false,
        },
      }
    },
    saveAttachment(data) {
      // //console.log(this.$refs[`uppy-data${this.dialogUppy.id}`]?.uppy?.getFiles())
      if (this.$refs[`uppy-data${this.dialogUppy.id}`]?.uppy?.getFiles().length) {
        this.chatbotData.promoList[this.chatbotData.promoList.findIndex(el => el.id === data.id)].promoAttachments =
          this.$refs[`uppy-data${this.dialogUppy.id}`]?.uppy?.getFiles()
      }
      this.dialogUppy.uppyDashboardSettings = {
        note: ' Image Only',

        // dipakai ketika edit
        // disabled: false,
      }
      //console.log(this.chatbotData.promoList, 'ini promolist')
      this.dialogUppy.show = false
    },
    deleteProcess(data) {
      this.deletePayload = data
      this.openDeleteDialog = true
    },
    async cancelDialog(checkbox = false) {
      this.dialogCreate = false
      this.ChatbotEdit = false
      this.lastChannelFlag = ''

      this.selectedInstance = {}
      this.chatbotData = {
        status: false,
        selectedShop: {},
        selectedPhoneNumber: '',
        greetingMsg: '',
        useKeyword: false,
        keywordMsg: '',
        talkToCustMsg: '',
        serveTo: '',
        closingMsg: '',
        showAddress: false,
        enableFaq: false,

        faqList: [
          {
            questionMsg: '',
            asnwerMsg: '',
            useTemplate: false,
            selectedTemplate: '',
          },
        ],
        enablePromo: false,
        promoList: [
          {
            id: this.$nanoid(),
            selectedOption: '',
            selectedTemplate: '',
            fromShopBanner: false,
            promoTitle: '',
            promoMessage: '',
            promoAttachments: [],
            promoLink: [],
          },
        ],
      }
      if (checkbox) {
        this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
      }
    },
    openEditChatbot(data) {
      this.dialogCreate = true
      this.ChatbotEdit = true

      const shop = this.shopData.find(el => el.id === data.selectedShop.id)
      const instance = this.instances.find(el => el.value === data.selectedPhoneNumber)
      this.lastChannelFlag = data.selectedPhoneNumber

      this.selectedInstance = instance
      this.chatbotData.status = data.status
      this.chatbotData.selectedShop = shop
      this.chatbotData.selectedPhoneNumber = ''
      this.chatbotData.greetingMsg = data.greetingMsg
      this.chatbotData.useKeyword = data.useKeyword
      this.chatbotData.keywordMsg = data.keywordMsg
      this.chatbotData.talkToCustMsg = data.talkToCustMsg
      this.chatbotData.serveTo = data.serveTo
      this.chatbotData.closingMsg = data.closingMsg
      this.chatbotData.showAddress = data.showAddress
      this.chatbotData.enableFaq = data.enableFaq
      this.chatbotData.faqList = data.faqList
      this.chatbotData.enablePromo = data.enablePromo
      this.chatbotData.promoList = data.promoList
    },
    async updateChatBot() {
      this.loading = true
      this.chatbotData.selectedPhoneNumber = this.selectedInstance.value

      const validate = this.validateChatbotPayload()

      if (validate) {
        await this.$store.dispatch('eOrdering/updateChatbot', {
          subId: this.user.sub_id,
          instanceId: this.selectedInstance.instance,
          chatbotData: {
            ...this.chatbotData,
            selectedShop: { id: this.chatbotData.selectedShop.id, excel: this.chatbotData.selectedShop.excelId },
          },

          // lastChannelFlag: this.lastChannelFlag,
        })
        this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
        this.loading = false
        this.cancelDialog()
      }
    },
    async deleteChatBot(data) {
      this.loading = true
      await this.$store.dispatch('eOrdering/deleteChatbot', {
        subId: this.user.sub_id,
        instanceId: data.instanceId,
        chatbotData: {
          id: data._id,
        },
      })
      this.deletePayload = null
      this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
      this.loading = false
      this.openDeleteDialog = false
    },
    async updateChatBotCheckbot(data) {
      let flag = true
      const shop = this.shopData.find(el => el.id === data.selectedShop.id)
      const instance = this.instances.find(el => el.value === data.selectedPhoneNumber)
      this.lastChannelFlag = data.selectedPhoneNumber

      this.selectedInstance = instance
      this.chatbotData.status = data.status
      this.chatbotData.selectedShop = shop
      this.chatbotData.greetingMsg = data.greetingMsg
      this.chatbotData.useKeyword = data.useKeyword
      this.chatbotData.keywordMsg = data.keywordMsg
      this.chatbotData.talkToCustMsg = data.talkToCustMsg
      this.chatbotData.serveTo = data.serveTo
      this.chatbotData.closingMsg = data.closingMsg
      this.chatbotData.showAddress = data.showAddress
      this.chatbotData.enableFaq = data.enableFaq
      this.chatbotData.faqList = data.faqList
      this.chatbotData.enablePromo = data.enablePromo
      this.chatbotData.promoList = data.promoList
      this.chatbotData.selectedPhoneNumber = this.selectedInstance.value

      if (this.chatbotData.useKeyword && !this.chatbotData.keywordMsg) {
        flag = false
        this.dialogCreate = true
        this.ChatbotEdit = true
        setTimeout(() => {
          // document.getElementById('yourScrollElementId').scrollTop = 999999
          const element = document.getElementById('useKeyword')
          element.scrollIntoView()
        }, 200)
      } else if (this.chatbotData.enableFaq && !this.chatbotData.faqList.length) {
        flag = false
        this.dialogCreate = true
        this.ChatbotEdit = true
        setTimeout(() => {
          // document.getElementById('yourScrollElementId').scrollTop = 999999
          const element = document.getElementById('enableFaq')
          element.scrollIntoView()
        }, 200)
      } else if (this.chatbotData.enablePromo && !this.chatbotData.promoList.length) {
        //console.log('masukkkkk3')
        flag = false
        this.dialogCreate = true
        this.ChatbotEdit = true
        setTimeout(() => {
          // document.getElementById('yourScrollElementId').scrollTop = 999999
          const element = document.getElementById('enablePromo')
          element.scrollIntoView()
        }, 200)
      }
      if (this.chatbotData.enableFaq && this.chatbotData.faqList.length) {
        //console.log('masukkkkk4')
        for (let i = 0; i < this.chatbotData.faqList.length; i++) {
          if (!this.chatbotData.faqList[i].questionMsg) {
            //console.log('masukkkkk5')
            flag = false
            this.dialogCreate = true
            this.ChatbotEdit = true

            setTimeout(() => {
              // document.getElementById('yourScrollElementId').scrollTop = 999999
              const element = document.getElementById('enableFaq')
              element.scrollIntoView()
            }, 200)
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            !this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].answerMsg
          ) {
            flag = false
            this.dialogCreate = true
            this.ChatbotEdit = true
            setTimeout(() => {
              // document.getElementById('yourScrollElementId').scrollTop = 999999
              const element = document.getElementById('enableFaq')
              element.scrollIntoView()
            }, 200)
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].selectedTemplate
          ) {
            flag = false
            this.dialogCreate = true
            this.ChatbotEdit = true
            setTimeout(() => {
              // document.getElementById('yourScrollElementId').scrollTop = 999999
              const element = document.getElementById('enableFaq')
              element.scrollIntoView()
            }, 200)
            break
          }
        }
      }
      if (this.chatbotData.enablePromo && this.chatbotData.promoList.length > 0) {
        for (let i = 0; i < this.chatbotData.promoList.length; i++) {
          if (!this.chatbotData.promoList[i].promoTitle) {
            flag = false
            this.dialogCreate = true
            this.ChatbotEdit = true
            setTimeout(() => {
              // document.getElementById('yourScrollElementId').scrollTop = 999999
              const element = document.getElementById('enablePromo')
              element.scrollIntoView()
            }, 200)
            break
          } else if (
            this.chatbotData.promoList[i].promoTitle &&
            this.chatbotData.promoList[i].selectedOption == 0 &&
            !this.chatbotData.promoList[i].selectedTemplate
          ) {
            flag = false
            this.dialogCreate = true
            this.ChatbotEdit = true
            setTimeout(() => {
              // document.getElementById('yourScrollElementId').scrollTop = 999999
              const element = document.getElementById('enablePromo')
              element.scrollIntoView()
            }, 200)
            break
          }
        }
      }
      if (flag) {
        this.loading = true
        await this.$store.dispatch('eOrdering/updateChatbot', {
          subId: this.user.sub_id,
          instanceId: this.selectedInstance.instance,
          chatbotData: {
            ...this.chatbotData,
            selectedShop: { id: this.chatbotData.selectedShop.id, excel: this.chatbotData.selectedShop.excelId },
          },

          // lastChannelFlag: this.lastChannelFlag,
        })
        this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
        this.loading = false

        this.cancelDialog()
      }

      // this.cancelDialog()
      // let validate = this.validateChatbotPayload()

      // if (validate) {
      //   await this.$store.dispatch('eOrdering/updateChatbot', {
      //     subId: this.user.sub_id,
      //     instanceId: this.selectedInstance.instance,
      //     chatbotData: {
      //       ...this.chatbotData,
      //       selectedShop: { id: this.chatbotData.selectedShop.id, excel: this.chatbotData.selectedShop.excelId },
      //     },
      //     lastChannelFlag: this.lastChannelFlag,
      //   })

      //   this.cancelDialog()
      // }
    },

    async createChatBot() {
      this.loading = true
      this.chatbotData.selectedPhoneNumber = this.selectedInstance.value

      const validate = this.validateChatbotPayload()

      if (validate) {
        await this.$store.dispatch('eOrdering/registerChatbot', {
          subId: this.user.sub_id,
          instanceId: this.selectedInstance.instance,
          chatbotData: {
            ...this.chatbotData,
            selectedShop: { id: this.chatbotData.selectedShop.id, excel: this.chatbotData.selectedShop.excelId },
          },
        })

        this.cancelDialog()
        this.listChatbot = await this.$store.dispatch('eOrdering/getChatbotList', {})
        this.loading = false
      }
    },
    validateSaveLink(data) {
      let checkker = true
      if (!data.label || !data.url) {
        this.errMessageLInk = 'Label and Url is required'
        checkker = false
        this.snackbarErrorLink = true
        //console.log(this.errMessage)
      } else if (!data.url.includes('http')) {
        this.errMessageLink = this.$t('broadcasts.linkAlert')
        checkker = false
        this.snackbarErrorLink = true
        //console.log(this.errMessage)
      }

      return checkker
    },
    validateChatbotPayload() {
      let checkker = true
      if (!this.chatbotData.selectedPhoneNumber) {
        // serveTo
        this.errMessage = 'Phone Number is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.selectedShop.hasOwnProperty('id')) {
        this.errMessage = 'Shop is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.useKeyword && !this.chatbotData.keywordMsg) {
        this.errMessage = 'Keyword Message is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.greetingMsg) {
        this.errMessage = 'Greeting Message is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.serveTo) {
        this.errMessage = 'serveTo is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.talkToCustMsg) {
        this.errMessage = 'Talk to Cs Message Is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (!this.chatbotData.closingMsg) {
        this.errMessage = 'Closing Message Is Required'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.enableFaq && !this.chatbotData.faqList.length) {
        this.errMessage = 'Please set Faq message'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      } else if (this.chatbotData.enablePromo && !this.chatbotData.promoList.length) {
        this.errMessage = 'Please set Promo message'
        checkker = false
        //console.log(this.errMessage)
        this.snackbarError = true
      }
      if (this.chatbotData.enableFaq && this.chatbotData.faqList.length) {
        for (let i = 0; i < this.chatbotData.faqList.length; i++) {
          if (!this.chatbotData.faqList[i].questionMsg) {
            this.errMessage = 'Question message is required'

            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            !this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].answerMsg
          ) {
            this.errMessage = 'Answer message is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.faqList[i].questionMsg &&
            this.chatbotData.faqList[i].useTemplate &&
            !this.chatbotData.faqList[i].selectedTemplate
          ) {
            this.errMessage = 'Answer message is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          }
        }
        //console.log(this.chatbotData.enablePromo, this.chatbotData.promoList.length)
      }
      if (this.chatbotData.enablePromo && this.chatbotData.promoList.length > 0) {
        for (let i = 0; i < this.chatbotData.promoList.length; i++) {
          //console.log(this.chatbotData.promoList)
          if (!this.chatbotData.promoList[i].promoTitle) {
            this.errMessage = 'Promo Title is required'

            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          } else if (
            this.chatbotData.promoList[i].promoTitle &&
            this.chatbotData.promoList[i].selectedOption == 0 &&
            !this.chatbotData.promoList[i].selectedTemplate
          ) {
            this.errMessage = 'Promo Output is required'
            checkker = false
            //console.log(this.errMessage)
            this.snackbarError = true
            break
          }

          // else if (
          //   this.chatbotData.promoList[i].promoTitle &&
          //   this.chatbotData.promoList[i].useTemplate &&
          //   !this.chatbotData.promoList[i].selectedTemplate.hasOwnProperty('message')
          // ) {
          //   this.errMessage = 'Answer message is required'
          //   //console.log(this.errMessage)
          //   break
          // }
        }
      }

      return checkker
    },
    async removePromo(index) {
      this.chatbotData.promoList = this.chatbotData.promoList.filter((promo, i) => i !== index)
    },
    async addPromoList() {
      this.chatbotData.promoList.push({
        id: this.$nanoid(),
        selectedOption: '',
        selectedTemplate: '',
        fromShopBanner: false,
        promoTitle: '',
        promoMessage: '',
        promoAttachments: [],
        promoLink: [],
      })
    },
    async removeFaq(index) {
      this.chatbotData.faqList = this.chatbotData.faqList.filter((faq, i) => i !== index)
    },
    async addFaqList() {
      this.chatbotData.faqList.push({
        questionMsg: '',
        answerMsg: '',
        useTemplate: false,
        selectedTemplate: '',
      })
    },
    async getDataTemplate(status, remote = false) {
      const dataTemplate = await this.loadSettingsById('message_templates', remote)

      // eslint-disable-next-line no-param-reassign
      if (dataTemplate === null) status = 'null'
      switch (status) {
        case 'message_templates': {
          this.listTemplate = [...dataTemplate.value]

          break
        }

        default: {
          this.listTemplate = []
        }
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 450px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.text-area-span {
  margin-top: -90px;
}

.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}

@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}

.additional-divider {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 32px;
  margin-right: 32px;
}
.additional-faq {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 10px;
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
  .additional-faq {
    border-left: 0px;
    border-top: 2px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: #afd0ff;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }
}
</style>
