<template>
  <div>
    <v-card>
      <v-card-title class="text-h4 grey lighten-2">
        <v-row>
          <v-col>
            <span class="me-2">{{ $t('EOrdering.openChatbotSetting') }}</span>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <div
            :class="[`text-subtitle-2`, `ml-3`, `mt-0`, `mb-4`, `font-weight-bold`, `red--text`]"
            v-html="$t('EOrdering.placeHolder')"
          ></div>
        </v-row>
      </v-card-text>
      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.title">
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <chatbot></chatbot>
        </v-tab-item>
      </v-tabs-items>
      <!-- <pre>{{listTemplate}}</pre> -->
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import uppyUploader from '@/components/UppyUploader.vue'
import pouchDbMixin from '@/mixins/pouchDbMixin'
import {
mdiAlert, mdiAttachment, mdiBroadcast, mdiChartMultiple, mdiChevronDown, mdiClose, mdiCloseCircle, mdiContentCopy, mdiDeleteOutline, mdiDotsVertical, mdiEye, mdiFormSelect, mdiInformation, mdiLink, mdiPaperclip, mdiPencil, mdiPlus, mdiPlusCircleOutline
} from '@mdi/js'
import autoFollowUp from './AutoFollowUp.vue'
import chatbot from './chatbot.vue'
import schedule from './Schedule.vue'

export default {
  name: 'Chatbot',
  components: { uppyUploader, autoFollowUp, chatbot, schedule },
  mixins: [pouchDbMixin],
  setup() {
    const tab = ref('')
    const tabs = [{ title: 'Auto Reply' }]
    return {
      icons: {
        mdiPaperclip,
        mdiFormSelect,
        mdiChevronDown,
        mdiDotsVertical,
        mdiPlus,
        mdiCloseCircle,
        mdiPencil,
        mdiDeleteOutline,
        mdiBroadcast,
        mdiEye,
        mdiContentCopy,
        mdiChartMultiple,
        mdiAlert,
        mdiLink,
        mdiAttachment,
        mdiPlusCircleOutline,
        mdiInformation,
        mdiClose,
      },
      tab,
      tabs
    }
  },
  data() {

}
}
</script>

<style scoped>
::v-deep .v-label {
  font-size: 1em;
}
::v-deep .v-label--active {
  font-size: 1.25em;
  font-weight: bold;
}
.v-input .v-label {
  font-size: 1em !important;
}
.v-input .v-label--active {
  font-size: 1.25em !important;
  font-weight: bold !important;
}
.mobile-col {
  display: block;
}
.width-dialog {
  max-width: 680px;
}
.height-dialog {
  height: 450px;
  overflow-y: auto;
}
.order-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
}
.mobile-col-show {
  display: none;
}
.dot {
  height: 40px;
  width: 40px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

@media (max-width: 1100px) {
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
}
.text-area-span {
  margin-top: -90px;
}

.mobile-span {
  display: inherit;
}
.margin-start4 {
  margin-left: 16px;
}
.wrapper {
  justify-content: center;
  padding: 20px 0;
  width: auto;
  text-align: center;
}
.description {
  text-align: center;
}
.map {
  width: 80%;
  height: 300px;
  display: inline-block;
  max-height: 100%;
  overflow: auto;
  border: 2px ridge silver;
  background-color: rgb(229, 227, 223);
}

@media screen and (max-width: 500px) {
  .description {
    display: none;
  }
}
@media (max-width: 600px) {
  .margin-start4 {
    margin-left: 0px;
  }
  .order-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
  .mobile-col-show {
    display: block;
  }
  .mobile-span {
    display: none;
  }
  .col-margin-top-mobile {
    margin-top: -30px;
  }
}

.additional-divider {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 32px;
  margin-right: 32px;
}
.additional-faq {
  border-left: 0px;
  border-top: 2px;
  border-right: 0px;
  border-bottom: 0px;
  border-style: solid;
  border-color: #afd0ff;
  margin-left: 100px;
  margin-right: 100px;
  padding-top: 10px;
}
@media (max-width: 960px) {
  .mobile-col {
    display: none;
  }
  .map {
    width: 100%;
    height: 300px;
    display: inline-block;
    max-height: 100%;
    overflow: auto;
    border: 2px ridge silver;
    background-color: rgb(229, 227, 223);
  }
  .additional-faq {
    border-left: 0px;
    border-top: 2px;
    border-right: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: #afd0ff;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 10px;
  }
}
</style>
